<template>
  <div class="fs-container">
    <div class="fs-section fs-news">
      <div class="fs-inr news-inr">
        <div class="fs-title">
          <h1>i-NEWS</h1>
        </div>

        <!-- PC show 6 cuc -->
        <div class="nav-news">
          <ul>
            <li
              v-for="(blogCategory, stt) in ListBlogCategories"
              :key="stt"
              :class="blogCategory.Id == currentCategory ? 'active' : ''"
            >
              <!--<a @click="currentCategory = blogCategory.Id;recruitmentCategoryActive=false">
                            <h2>{{blogCategory.Name}}</h2>
                        </a>-->
              <router-link :to="$t('Route.INews') + '/' + blogCategory.Slug">
                <h2>{{ blogCategory.Name }}</h2>
              </router-link>
            </li>
            <li :class="recruitmentCategoryActive ? 'active' : ''">
              <!--<a @click="currentCategory = 0;recruitmentCategoryActive=true;">
                            <h2>{{ $t('Common.Recruitment') }}</h2>
                        </a>-->
              <router-link :to="$t('Route.Recruit')">
                <h2>{{ $t("Common.Recruitment") }}</h2>
              </router-link>
            </li>
          </ul>
        </div>

        <template
          v-if="
            currentCategory > 0 &&
            ListBlogCategories.find((i) => i.Id == currentCategory)
              .LayoutType == 1 &&
            !showGrid
          "
        >
          <router-link
            class="main-news news-item"
            v-if="MainBlog.length > 0"
            :to="
              $t('Route.INews') +
              '/' +
              MainBlog[0].BlogCategorySlug +
              '/' +
              MainBlog[0].Slug
            "
          >
            <div class="fs-pic">
              <img
                class="fs-pc pcPic"
                :src="MainBlog[0].Thumbnail"
                :alt="MainBlog[0].Title"
              />
              <img
                class="fs-sp spPic"
                :src="MainBlog[0].Thumbnail"
                :alt="MainBlog[0].Title"
              />
            </div>
            <div class="fs-txt">
              <div class="user-box">
                <div class="user-pic">
                  <img
                    :src="MainBlog[0].AuthorIcon"
                    :alt="MainBlog[0].Author"
                  />
                </div>
                <div class="user-txt">{{ MainBlog[0].Author }}</div>
              </div>
              <h3 v-html="MainBlog[0].Title"></h3>
            </div>
          </router-link>

          <div class="hot-news" v-if="currentCategory > 0">
            <h2 class="title">{{ $t("Common.HOT NEWS") }}</h2>
            <router-link
              class="view-more"
              :to="
                $t('Route.INews') +
                '/' +
                ListBlogCategories.find((i) => i.Id == currentCategory).Slug +
                '/' +
                $t('Route.ViewMore') +
                '/tin-noi-bat'
              "
              >{{ $t("Common.See all") }}
            </router-link>
            <div class="hot-items">
              <router-link
                class="hot-item news-item"
                v-for="(blog, i) of ListFeatureBlog"
                :key="blog.Id"
                :to="
                  $t('Route.INews') +
                  '/' +
                  blog.BlogCategorySlug +
                  '/' +
                  blog.Slug
                "
              >
                <div
                  class="fs-bg cmBg"
                  :style="'background-image:url(' + blog.Thumbnail + ')'"
                ></div>
                <div v-if="i != 1 && i != 2" class="fs-txt">
                  <h3 v-html="blog.Title"></h3>
                  <!-- <span class="hot-caption">{{blog.ReadTime}} Min Read</span> -->
                </div>
                <div v-if="i == 1 || i == 2" class="fs-txt">
                  <div class="user-box">
                    <div class="user-pic">
                      <img :src="blog.AuthorIcon" :alt="blog.Author" />
                    </div>
                    <div class="user-txt">{{ blog.Author }}</div>
                  </div>
                  <h3 v-html="blog.Title"></h3>
                </div>
              </router-link>
            </div>
          </div>

          <div
            class="i-videos"
            v-if="ListVideoBlog.length > 0 && currentCategory > 0"
          >
            <h2 class="title">i-VIDEO</h2>
            <router-link
              class="view-more"
              :to="
                $t('Route.INews') +
                '/' +
                ListBlogCategories.find((i) => i.Id == currentCategory).Slug +
                '/' +
                $t('Route.ViewMore') +
                '/i-video'
              "
              >{{ $t("Common.See all") }}</router-link
            >
            <div class="video-block">
              <div class="video-main news-item" @click="playVideo()">
                <div
                  class="fs-bg cmBg"
                  :style="
                    'background-image:url(' +
                    ListVideoBlog[currentVideo].Thumbnail +
                    ')'
                  "
                ></div>
                <div class="fs-txt">
                  <div class="user-box">
                    <div class="user-pic">
                      <img
                        :src="ListVideoBlog[currentVideo].AuthorIcon"
                        :alt="ListVideoBlog[currentVideo].Author"
                      />
                    </div>
                    <div class="user-txt">
                      {{ ListVideoBlog[currentVideo].Author }}
                    </div>
                  </div>
                  <h3 v-html="ListVideoBlog[currentVideo].Title"></h3>
                </div>
                <div
                  class="video-but type-red open-inline"
                  :data-video="ListVideoBlog[currentVideo].VideoUrl"
                ></div>
                <div class="video-inline">
                  <iframe
                    id="mainVideo"
                    src=""
                    allow="autoplay; encrypted-media"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>

              <div class="video-list">
                <div
                  class="video-item"
                  v-for="(videoBlog, i) of ListVideoBlog"
                  :class="currentVideo == i ? 'active' : ''"
                  @click="currentVideo = i"
                  :key="videoBlog.Id"
                >
                  <div class="fs-pic">
                    <img :src="videoBlog.Thumbnail" :alt="videoBlog.Author" />
                    <div
                      class="video-but type-grey open-inline"
                      :data-video="videoBlog.VideoUrl"
                    ></div>
                  </div>
                  <div class="fs-txt">
                    <h3 v-html="videoBlog.Title"></h3>
                    <p>{{ videoBlog.Tagging }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="i-magazine"
            v-if="ListMagazineBlog.length > 0 && currentCategory > 0"
          >
            <h2 class="title">i-MAGAZINE</h2>
            <router-link
              class="view-more"
              :to="
                $t('Route.INews') +
                '/' +
                ListBlogCategories.find((i) => i.Id == currentCategory).Slug +
                '/' +
                $t('Route.ViewMore') +
                '/i-magazine'
              "
              >{{ $t("Common.See all") }}</router-link
            >
            <div class="magazine-items">
              <router-link
                class="magazine-item news-item"
                :to="
                  $t('Route.INews') +
                  '/' +
                  magazineBlog.BlogCategorySlug +
                  '/' +
                  magazineBlog.Slug
                "
                v-for="magazineBlog of ListMagazineBlog"
                :key="magazineBlog.Id"
              >
                <div
                  class="fs-bg cmBg"
                  :style="
                    'background-image:url(' + magazineBlog.Thumbnail + ')'
                  "
                ></div>
                <div class="fs-txt">
                  <div class="user-box">
                    <div class="user-pic">
                      <img
                        :src="magazineBlog.AuthorIcon"
                        :alt="magazineBlog.Author"
                      />
                    </div>
                    <div class="user-txt">{{ magazineBlog.Author }}</div>
                  </div>
                  <h3 v-html="magazineBlog.Title"></h3>
                </div>
              </router-link>
            </div>
          </div>
        </template>

        <template
          v-if="
            (currentCategory > 0 &&
              ListBlogCategories.find((i) => i.Id == currentCategory)
                .LayoutType == 2) ||
            showGrid
          "
        >
          <div class="news-list">
            <template v-for="blog of BlockBlog">
              <a
                :key="blog.Id"
                v-if="blog.IsTypeVideo"
                class="news-item type-video"
              >
                <div
                  class="fs-bg cmBg"
                  :style="'background-image:url(' + blog.Thumbnail + ')'"
                ></div>
                <div class="fs-txt">
                  <h3 v-html="blog.Title"></h3>
                  <p v-html="blog.Brief"></p>
                </div>
                <div class="user-box">
                  <div class="user-pic">
                    <img :src="blog.AuthorIcon" :alt="blog.Author" />
                  </div>
                  <div class="user-txt">{{ blog.Author }}</div>
                </div>
                <div class="news-type">I-VIDEO</div>
                <div
                  class="video-but open-video-pop"
                  @click="openVideo(blog.VideoUrl)"
                  :data-video="blog.VideoUrl"
                ></div>
              </a>
              <router-link
                :key="blog.Id"
                v-if="!blog.IsTypeVideo"
                class="news-item"
                :to="
                  $t('Route.INews') +
                  '/' +
                  blog.BlogCategorySlug +
                  '/' +
                  blog.Slug
                "
              >
                <div
                  class="fs-bg cmBg"
                  :style="'background-image:url(' + blog.Thumbnail + ')'"
                ></div>
                <div class="fs-txt">
                  <h3 v-html="blog.Title"></h3>
                  <p v-html="blog.Brief"></p>
                </div>
                <div class="user-box">
                  <div class="user-pic">
                    <img :src="blog.AuthorIcon" :alt="blog.Author" />
                  </div>
                  <div class="user-txt">blog.Author</div>
                </div>
              </router-link>
            </template>
          </div>

          <!--Video Popup-->
          <div :class="'modal ' + modalClass" id="video-modal">
            <div class="overlay" @click="closeVideo()"></div>
            <div class="modal-inner">
              <a class="close-button" @click="closeVideo()"></a>
              <iframe
                :src="videoId"
                allow="autoplay; encrypted-media"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </template>

        <template v-if="currentCategory == 0 && recruitmentCategoryActive">
          <div class="news-list">
            <router-link
              class="news-item"
              :key="recruitment.Id"
              v-for="recruitment in Recruitment"
              :to="$t('Route.Recruit') + '/' + recruitment.Slug"
            >
              <div
                class="fs-bg cmBg"
                :style="'background-image:url(' + recruitment.Thumbnail + ')'"
              ></div>

              <div class="fs-txt">
                <h3 v-html="recruitment.Title"></h3>
                <p>
                  {{ recruitment.Brief }}
                </p>
              </div>

              <div class="user-box">
                <div class="user-pic">
                  <img
                    :src="recruitment.AuthorIcon"
                    :alt="recruitment.Author"
                  />
                </div>
                <div class="user-txt">{{ recruitment.Author }}</div>
              </div>
            </router-link>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { Navigation, Pagination } from "swiper";
import { SwiperCore, Swiper, SwiperSlide } from "swiper-vue2";
import useJwt from "@/auth/jwt/useJwt";
import EventBus from "@/global-components";
import { isMobile } from "mobile-device-detect";

// Import Swiper styles
import "swiper/swiper-bundle.css";
SwiperCore.use([Navigation, Pagination]);
function importAll(r) {
  let images = {};
  r.keys().map((item, index) => {
    images["assets/images/ve-itel/" + item.replace("./", "")] = r(item);
  });
  return images;
}
const images = importAll(
  require.context("@/assets/images/ve-itel/", false, /.(png)$/)
);

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      ListBlogCategories: [],
      MainBlog: [],
      ListFeatureBlog: [],
      ListVideoBlog: [],
      ListMagazineBlog: [],
      BlockBlog: [],
      Recruitment: [],
      currentVideo: 0,
      currentCategory: 0,
      videoId: "",
      modalClass: "",
      recruitmentCategoryActive: false,
      showGrid: false,
      showGridType: "",
    };
  },
  watch: {
    $route(to, from) {
      this.fetch();
    },
    currentCategory: function (i, v) {
      this.loading(true);
      this.getData();
    },
    showGridType: function (i, v) {
      this.loading(true);
      this.getData();
    },
    currentVideo: function (i, v) {
      this.playVideo();
    },
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log("slide change");
    };
    return {
      onSwiper,
      onSlideChange,
    };
  },
  mounted() {
    // fetch data recruitment category
    if (this.$route.name == "tuyen-dung") {
      this.fetchDataRecuitment();
    }
    this.fetch();
    var t_ = this;
    EventBus.$on("CHANGE_LANG", function (payLoad) {
      t_.fetch();
    });
  },
  methods: {
    Img(pic) {
      return images[pic];
    },
    getData() {
      if (this.currentCategory > 0) {
        if (
          this.ListBlogCategories.find((i) => i.Id == this.currentCategory)
            .LayoutType == 1
        ) {
          useJwt
            .post("getMasterData/listBlog", {
              columnFilters: { BlogCategoryId: this.currentCategory },
              sort: [],
              page: 1,
              pageSize: 1000,
              lang: this.$l,
            })
            .then((response) => {
              this.MainBlog = response.data.result.MainBlog;
              this.ListFeatureBlog = response.data.result.ListFeatureBlog;
              this.ListVideoBlog = response.data.result.ListVideoBlog;
              this.ListMagazineBlog = response.data.result.ListMagazineBlog;
              if (this.showGridType == "tin-noi-bat") {
                this.BlockBlog = this.ListFeatureBlog;
              } else if (this.showGridType == "i-video") {
                this.BlockBlog = this.ListVideoBlog;
              } else if (this.showGridType == "i-magazine") {
                this.BlockBlog = this.ListMagazineBlog;
              }
              this.loading(false);
            })
            .catch((err) => {
              this.loading(false);
              this.$toast.error(
                "Có lỗi trong khi lấy dữ liệu trang, Quý khách vui lòng thử lại!",
                { icon: true, closeButton: "button" }
              );
            });
        } else if (
          this.ListBlogCategories.find((i) => i.Id == this.currentCategory)
            .LayoutType == 2
        ) {
          useJwt
            .post("getMasterData/blockBlog", {
              columnFilters: { BlogCategoryId: this.currentCategory },
              sort: [],
              page: 1,
              pageSize: 1000,
              lang: this.$l,
            })
            .then((response) => {
              this.BlockBlog = response.data.result.BlockBlog;
              console.log("BlockBlog", this.BlockBlog);
              this.loading(false);
            })
            .catch((err) => {
              this.loading(false);
              this.$toast.error(
                "Có lỗi trong khi lấy dữ liệu trang, Quý khách vui lòng thử lại!",
                { icon: true, closeButton: "button" }
              );
            });
        }
      } else {
        this.fetchDataRecuitment();
      }
    },

    fetch() {
      useJwt
        .post("getMasterData/category", {
          columnFilters: {},
          sort: [],
          page: 1,
          pageSize: 1000,
          lang: this.$l,
        })
        .then((response) => {
          this.ListBlogCategories = response.data.result.ListBlogCategories;
          if (this.$route.params.Type != undefined) {
            this.showGrid = true;
            this.showGridType = this.$route.params.Type;
          } else {
            this.showGrid = false;
            this.showGridType = "";
          }

          if (
            this.$route.params.Id != undefined &&
            this.$route.params.Id != ""
          ) {
            var f = this.ListBlogCategories.find(
              (i) => i.Slug == this.$route.params.Id
            );
            if (f != undefined) {
              this.currentCategory = f.Id;
            } else {
              this.currentCategory = this.ListBlogCategories[0].Id;
            }
            this.recruitmentCategoryActive = false;
          } else if (
            this.$route.meta.isRecruit != undefined &&
            this.$route.meta.isRecruit
          ) {
            console.log("vao roter app parram1 ");
            this.currentCategory = 0;
            this.recruitmentCategoryActive = true;
          } else {
            console.log("vao roter app parram2 ");
            this.currentCategory = this.ListBlogCategories[0].Id;
            this.recruitmentCategoryActive = false;
          }
        });
    },
    fetchDataRecuitment() {
      useJwt
        .post("getMasterData/Recruitment", {
          columnFilters: {},
          sort: [],
          page: 1,
          pageSize: 1000,
          lang: this.$l,
        })
        .then((response) => {
          this.Recruitment = response.data.result.Recruitment;
          console.log(this.Recruitment);
          this.loading(false);
        })
        .catch((err) => {
          this.loading(false);
          this.$toast.error(
            "Có lỗi trong khi lấy dữ liệu trang, Quý khách vui lòng thử lại!",
            { icon: true, closeButton: "button" }
          );
        });
    },
    playVideo() {
      const video = document.getElementsByClassName("video-main news-item");
      video[0].children[3].children[0].setAttribute(
        "src",
        "https://www.youtube.com/embed/" +
          this.ListVideoBlog[this.currentVideo].VideoUrl +
          "?rel=0&amp;autoplay=1&amp;playsinline=1"
      );
      if (!video[0].classList.contains("show-video")) {
        video[0].classList.add("show-video");
      }
    },
    openVideo(url) {
      this.modalClass = "modal__open";
      this.videoId =
        "https://www.youtube.com/embed/" +
        url +
        "?rel=0&amp;autoplay=1&amp;playsinline=1";
    },
    closeVideo() {
      this.videoId = "";
      this.modalClass = "";
    },
  },
};
</script>