var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fs-container"},[_c('div',{staticClass:"fs-section fs-news"},[_c('div',{staticClass:"fs-inr news-inr"},[_vm._m(0),_c('div',{staticClass:"nav-news"},[_c('ul',[_vm._l((_vm.ListBlogCategories),function(blogCategory,stt){return _c('li',{key:stt,class:blogCategory.Id == _vm.currentCategory ? 'active' : ''},[_c('router-link',{attrs:{"to":_vm.$t('Route.INews') + '/' + blogCategory.Slug}},[_c('h2',[_vm._v(_vm._s(blogCategory.Name))])])],1)}),_c('li',{class:_vm.recruitmentCategoryActive ? 'active' : ''},[_c('router-link',{attrs:{"to":_vm.$t('Route.Recruit')}},[_c('h2',[_vm._v(_vm._s(_vm.$t("Common.Recruitment")))])])],1)],2)]),(
          _vm.currentCategory > 0 &&
          _vm.ListBlogCategories.find(function (i) { return i.Id == _vm.currentCategory; })
            .LayoutType == 1 &&
          !_vm.showGrid
        )?[(_vm.MainBlog.length > 0)?_c('router-link',{staticClass:"main-news news-item",attrs:{"to":_vm.$t('Route.INews') +
            '/' +
            _vm.MainBlog[0].BlogCategorySlug +
            '/' +
            _vm.MainBlog[0].Slug}},[_c('div',{staticClass:"fs-pic"},[_c('img',{staticClass:"fs-pc pcPic",attrs:{"src":_vm.MainBlog[0].Thumbnail,"alt":_vm.MainBlog[0].Title}}),_c('img',{staticClass:"fs-sp spPic",attrs:{"src":_vm.MainBlog[0].Thumbnail,"alt":_vm.MainBlog[0].Title}})]),_c('div',{staticClass:"fs-txt"},[_c('div',{staticClass:"user-box"},[_c('div',{staticClass:"user-pic"},[_c('img',{attrs:{"src":_vm.MainBlog[0].AuthorIcon,"alt":_vm.MainBlog[0].Author}})]),_c('div',{staticClass:"user-txt"},[_vm._v(_vm._s(_vm.MainBlog[0].Author))])]),_c('h3',{domProps:{"innerHTML":_vm._s(_vm.MainBlog[0].Title)}})])]):_vm._e(),(_vm.currentCategory > 0)?_c('div',{staticClass:"hot-news"},[_c('h2',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("Common.HOT NEWS")))]),_c('router-link',{staticClass:"view-more",attrs:{"to":_vm.$t('Route.INews') +
              '/' +
              _vm.ListBlogCategories.find(function (i) { return i.Id == _vm.currentCategory; }).Slug +
              '/' +
              _vm.$t('Route.ViewMore') +
              '/tin-noi-bat'}},[_vm._v(_vm._s(_vm.$t("Common.See all"))+" ")]),_c('div',{staticClass:"hot-items"},_vm._l((_vm.ListFeatureBlog),function(blog,i){return _c('router-link',{key:blog.Id,staticClass:"hot-item news-item",attrs:{"to":_vm.$t('Route.INews') +
                '/' +
                blog.BlogCategorySlug +
                '/' +
                blog.Slug}},[_c('div',{staticClass:"fs-bg cmBg",style:('background-image:url(' + blog.Thumbnail + ')')}),(i != 1 && i != 2)?_c('div',{staticClass:"fs-txt"},[_c('h3',{domProps:{"innerHTML":_vm._s(blog.Title)}})]):_vm._e(),(i == 1 || i == 2)?_c('div',{staticClass:"fs-txt"},[_c('div',{staticClass:"user-box"},[_c('div',{staticClass:"user-pic"},[_c('img',{attrs:{"src":blog.AuthorIcon,"alt":blog.Author}})]),_c('div',{staticClass:"user-txt"},[_vm._v(_vm._s(blog.Author))])]),_c('h3',{domProps:{"innerHTML":_vm._s(blog.Title)}})]):_vm._e()])}),1)],1):_vm._e(),(_vm.ListVideoBlog.length > 0 && _vm.currentCategory > 0)?_c('div',{staticClass:"i-videos"},[_c('h2',{staticClass:"title"},[_vm._v("i-VIDEO")]),_c('router-link',{staticClass:"view-more",attrs:{"to":_vm.$t('Route.INews') +
              '/' +
              _vm.ListBlogCategories.find(function (i) { return i.Id == _vm.currentCategory; }).Slug +
              '/' +
              _vm.$t('Route.ViewMore') +
              '/i-video'}},[_vm._v(_vm._s(_vm.$t("Common.See all")))]),_c('div',{staticClass:"video-block"},[_c('div',{staticClass:"video-main news-item",on:{"click":function($event){return _vm.playVideo()}}},[_c('div',{staticClass:"fs-bg cmBg",style:('background-image:url(' +
                  _vm.ListVideoBlog[_vm.currentVideo].Thumbnail +
                  ')')}),_c('div',{staticClass:"fs-txt"},[_c('div',{staticClass:"user-box"},[_c('div',{staticClass:"user-pic"},[_c('img',{attrs:{"src":_vm.ListVideoBlog[_vm.currentVideo].AuthorIcon,"alt":_vm.ListVideoBlog[_vm.currentVideo].Author}})]),_c('div',{staticClass:"user-txt"},[_vm._v(" "+_vm._s(_vm.ListVideoBlog[_vm.currentVideo].Author)+" ")])]),_c('h3',{domProps:{"innerHTML":_vm._s(_vm.ListVideoBlog[_vm.currentVideo].Title)}})]),_c('div',{staticClass:"video-but type-red open-inline",attrs:{"data-video":_vm.ListVideoBlog[_vm.currentVideo].VideoUrl}}),_vm._m(1)]),_c('div',{staticClass:"video-list"},_vm._l((_vm.ListVideoBlog),function(videoBlog,i){return _c('div',{key:videoBlog.Id,staticClass:"video-item",class:_vm.currentVideo == i ? 'active' : '',on:{"click":function($event){_vm.currentVideo = i}}},[_c('div',{staticClass:"fs-pic"},[_c('img',{attrs:{"src":videoBlog.Thumbnail,"alt":videoBlog.Author}}),_c('div',{staticClass:"video-but type-grey open-inline",attrs:{"data-video":videoBlog.VideoUrl}})]),_c('div',{staticClass:"fs-txt"},[_c('h3',{domProps:{"innerHTML":_vm._s(videoBlog.Title)}}),_c('p',[_vm._v(_vm._s(videoBlog.Tagging))])])])}),0)])],1):_vm._e(),(_vm.ListMagazineBlog.length > 0 && _vm.currentCategory > 0)?_c('div',{staticClass:"i-magazine"},[_c('h2',{staticClass:"title"},[_vm._v("i-MAGAZINE")]),_c('router-link',{staticClass:"view-more",attrs:{"to":_vm.$t('Route.INews') +
              '/' +
              _vm.ListBlogCategories.find(function (i) { return i.Id == _vm.currentCategory; }).Slug +
              '/' +
              _vm.$t('Route.ViewMore') +
              '/i-magazine'}},[_vm._v(_vm._s(_vm.$t("Common.See all")))]),_c('div',{staticClass:"magazine-items"},_vm._l((_vm.ListMagazineBlog),function(magazineBlog){return _c('router-link',{key:magazineBlog.Id,staticClass:"magazine-item news-item",attrs:{"to":_vm.$t('Route.INews') +
                '/' +
                magazineBlog.BlogCategorySlug +
                '/' +
                magazineBlog.Slug}},[_c('div',{staticClass:"fs-bg cmBg",style:('background-image:url(' + magazineBlog.Thumbnail + ')')}),_c('div',{staticClass:"fs-txt"},[_c('div',{staticClass:"user-box"},[_c('div',{staticClass:"user-pic"},[_c('img',{attrs:{"src":magazineBlog.AuthorIcon,"alt":magazineBlog.Author}})]),_c('div',{staticClass:"user-txt"},[_vm._v(_vm._s(magazineBlog.Author))])]),_c('h3',{domProps:{"innerHTML":_vm._s(magazineBlog.Title)}})])])}),1)],1):_vm._e()]:_vm._e(),(
          (_vm.currentCategory > 0 &&
            _vm.ListBlogCategories.find(function (i) { return i.Id == _vm.currentCategory; })
              .LayoutType == 2) ||
          _vm.showGrid
        )?[_c('div',{staticClass:"news-list"},[_vm._l((_vm.BlockBlog),function(blog){return [(blog.IsTypeVideo)?_c('a',{key:blog.Id,staticClass:"news-item type-video"},[_c('div',{staticClass:"fs-bg cmBg",style:('background-image:url(' + blog.Thumbnail + ')')}),_c('div',{staticClass:"fs-txt"},[_c('h3',{domProps:{"innerHTML":_vm._s(blog.Title)}}),_c('p',{domProps:{"innerHTML":_vm._s(blog.Brief)}})]),_c('div',{staticClass:"user-box"},[_c('div',{staticClass:"user-pic"},[_c('img',{attrs:{"src":blog.AuthorIcon,"alt":blog.Author}})]),_c('div',{staticClass:"user-txt"},[_vm._v(_vm._s(blog.Author))])]),_c('div',{staticClass:"news-type"},[_vm._v("I-VIDEO")]),_c('div',{staticClass:"video-but open-video-pop",attrs:{"data-video":blog.VideoUrl},on:{"click":function($event){return _vm.openVideo(blog.VideoUrl)}}})]):_vm._e(),(!blog.IsTypeVideo)?_c('router-link',{key:blog.Id,staticClass:"news-item",attrs:{"to":_vm.$t('Route.INews') +
                '/' +
                blog.BlogCategorySlug +
                '/' +
                blog.Slug}},[_c('div',{staticClass:"fs-bg cmBg",style:('background-image:url(' + blog.Thumbnail + ')')}),_c('div',{staticClass:"fs-txt"},[_c('h3',{domProps:{"innerHTML":_vm._s(blog.Title)}}),_c('p',{domProps:{"innerHTML":_vm._s(blog.Brief)}})]),_c('div',{staticClass:"user-box"},[_c('div',{staticClass:"user-pic"},[_c('img',{attrs:{"src":blog.AuthorIcon,"alt":blog.Author}})]),_c('div',{staticClass:"user-txt"},[_vm._v("blog.Author")])])]):_vm._e()]})],2),_c('div',{class:'modal ' + _vm.modalClass,attrs:{"id":"video-modal"}},[_c('div',{staticClass:"overlay",on:{"click":function($event){return _vm.closeVideo()}}}),_c('div',{staticClass:"modal-inner"},[_c('a',{staticClass:"close-button",on:{"click":function($event){return _vm.closeVideo()}}}),_c('iframe',{attrs:{"src":_vm.videoId,"allow":"autoplay; encrypted-media","allowfullscreen":""}})])])]:_vm._e(),(_vm.currentCategory == 0 && _vm.recruitmentCategoryActive)?[_c('div',{staticClass:"news-list"},_vm._l((_vm.Recruitment),function(recruitment){return _c('router-link',{key:recruitment.Id,staticClass:"news-item",attrs:{"to":_vm.$t('Route.Recruit') + '/' + recruitment.Slug}},[_c('div',{staticClass:"fs-bg cmBg",style:('background-image:url(' + recruitment.Thumbnail + ')')}),_c('div',{staticClass:"fs-txt"},[_c('h3',{domProps:{"innerHTML":_vm._s(recruitment.Title)}}),_c('p',[_vm._v(" "+_vm._s(recruitment.Brief)+" ")])]),_c('div',{staticClass:"user-box"},[_c('div',{staticClass:"user-pic"},[_c('img',{attrs:{"src":recruitment.AuthorIcon,"alt":recruitment.Author}})]),_c('div',{staticClass:"user-txt"},[_vm._v(_vm._s(recruitment.Author))])])])}),1)]:_vm._e()],2)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fs-title"},[_c('h1',[_vm._v("i-NEWS")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"video-inline"},[_c('iframe',{attrs:{"id":"mainVideo","src":"","allow":"autoplay; encrypted-media","allowfullscreen":""}})])}]

export { render, staticRenderFns }